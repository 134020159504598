<template>
    
  <v-footer
    color="grey lighten-3 px-6 py-3"
    height="auto"
    min-height="64"
  >
    <v-row justify="center">
      <section>
        <v-container>
            <v-row>
            <v-col
                v-for="([icon, info1, info2], i) in details"
                :key="i"
                cols="12"
                md="3"
            >
                <div class="text-center">
                <v-icon
                    size="64"
                    class="mb-3"
                    v-text="icon"
                />
                <div
                    class="mb-2 headline"
                    v-text="info1"
                />
                <div 
                    class="mb-2 headline" 
                    v-text="info2" />
                </div>
            </v-col>
            </v-row>
        </v-container>
        </section>
    </v-row>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      details: [
        ['mdi-home', 'Lembitu 6', 'Tartu, 50406'],
        ['mdi-email', 'arhiiv@eba.ee', ''],
        ['mdi-phone', '6 551 123', '7 428 850'],
        ['mdi-home', 'Piirimäe 8', 'Tallinn, Saku vald']
      ]
    })
  }
</script>

<style lang="sass" scoped>
  .v-footer a
    text-decoration: none
</style>
